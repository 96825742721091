import "instantsearch.css/themes/algolia-min.css"
import React, { Component } from "react"
import { InstantSearch, Hits, SearchBox, Stats, Highlight } from "react-instantsearch-dom"
import "./App.css"
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch"

const searchClient = instantMeiliSearch("https://db.stonking.com", "7303fa3e6a372196bcb26b7b4d5df747e5092245407a05466815655f8f32ae30", {
  paginationTotalHits: 1
})

class App extends Component {
  render() {
    return (
      <div className="ais-InstantSearch">
        <h1>Stonking Search</h1>
        <p>
          Search our demo and follow us on{" "}
          <a href="https://twitter.com/stonking" rel="noopener" target="_blank">
            Twitter
          </a>{" "}
          for updates and early access.
        </p>
        <InstantSearch indexName="movies" searchClient={searchClient}>
          <Stats />
          <SearchBox
            translations={{
              submitTitle: "Submit your search query.",
              resetTitle: "Clear your search query.",
              placeholder: "Search for something, e.g. Charlie's Angels"
            }}
          />
          <Hits hitComponent={Hit} />
        </InstantSearch>
      </div>
    )
  }
}

function Hit(props) {
  return (
    <div key={props.hit.id}>
      <img src={props.hit.poster} align="left" alt={props.hit.title} width="200px" />
      <div className="hit-name">
        <Highlight attribute="title" hit={props.hit} />
      </div>
      <div className="hit-description">
        <Highlight attribute="overview" hit={props.hit} />
      </div>
    </div>
  )
}

export default App
